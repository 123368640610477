// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: 'Nunito', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Colors
$blue: #64add1;
//$indigo: #6574cd;
$purple: #6d70a6;
//$pink: #f66d9b;
$red: #DD634B;
$orange: #f58220;
$yellow: #fbb040;
$dark-green: #007932;
$light-green: #b0b91d;
$gray: #939598;
$light-gray: #D9D9D9;
//$green: #38c172;
//$teal: #4dc0b5;
//$cyan: #6cb2eb;

$primary: $orange;
$secondary: $gray;
$success: $dark-green;
$danger: $red;
$warning: $yellow;
$info: $blue;
$black: #000;
