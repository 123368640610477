// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

.b-form-btn-label-control.form-control > .form-control {
    flex: none !important;
}

// Brand colors.
.bg-blue {
    background-color: $blue
};
.bg-purple {
    background-color: $purple
};
.bg-red {
    background-color: $red
};
.bg-orange {
    background-color: $orange
};
.bg-yellow {
    background-color: $yellow
};
.bg-dark-green {
    background-color: $dark-green
};
.bg-light-green {
    background-color: $light-green
};
.bg-gray {
    background-color: $gray
};
.bg-light-gray {
    background-color: $light-gray
};

.text-blue {
    color: $blue
}
.text-purple {
    color: $purple
}
.text-red {
    color: $red
}
.text-orange {
    color: $orange
}
.text-yellow {
    color: $yellow
}
.text-dark-green {
    color: $dark-green
}
.text-light-green {
    color: $light-green
}
.text-gray {
    color: $gray
}
.bg-light-gray {
    background-color: $light-gray
};

.text-sm {
    font-size: 0.875rem;
    line-height: 1.25rem;
}

.btn {
    font-size: 1.0em !important;
    border-radius: 6px !important;
    padding: 0.175rem 0.75rem 0.125rem 0.75rem;
    border-color: #723602 !important;

    .btn-primary {
        border-color: #723602 !important;
        color: #FFFFFF !important;
    }

    .btn-danger {
        border-color: #942009;
        color: #FFFFFF !important;
    }

    .btn-info {
        border-color: #723602;
        color: #FFFFFF !important;
    }

    .btn-light {
        border-color: #FFFFFF !important;
        color: #FFFFFF !important;
    }

    .btn-light:hover {
        color: #900000 !important;
    }
}

.btn-data-table {
    background: linear-gradient(to bottom, rgba(230,230,230,0.1) 0%, rgba(0,0,0,0.1) 100%);
    border-color: #ADADAD !important;
    color: $black !important;
}

.btn-data-table:hover {
    // Make the background color a vertical gradient from f0f0f0 to e5e5e5.
    background: #e5e5e5 !important;
}

.btn:hover {
    background-color: #696969;
}

.fas.fa-arrow-circle-right {
    font-size: 14px;
}

label.form-check-label {
    padding-top: 0 !important;
}

.cursor-pointer {
    cursor: pointer;
}

/**
 * Sticky table header stuff.
 */
.table-fix-head {
    overflow: auto;
}

.table-fix-head thead tr th {
    position: sticky;
    top: 0;
    z-index: 1;
    background: #ffffff;
}

.w-60 {
    width: 60% !important;
}

.w-80 {
    width: 80% !important;
}

.w-90 {
    width: 90% !important;
}
